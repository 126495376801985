import Rails from "@rails/ujs"

import 'jquery'
import 'popper.js'
import 'bootstrap'
import 'packs/reset_password.js'
import 'packs/authentications.js'
import 'packs/password_grant_type.js'
import 'packs/mfa.js'
import 'css/application'

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

Rails.start()
