window.addEventListener('load', function() {
  var form = document.getElementById('mfa-code-form');
  var codeInput = document.getElementById('mfa-code-input');

  var validateCode = function() {
    if(/^\d{6}$/.test(codeInput.value)) {
      codeInput.setCustomValidity('');
    }else{
      codeInput.setCustomValidity('invalid code');
    }

    form.classList.remove('was-validated');
  };

  if (form) {
    codeInput.addEventListener('input', function(_){
      form.classList.remove('was-validated');
    });

    form.addEventListener('submit', function (event) {
      form.classList.remove('was-validated');
      validateCode();
      if(!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }

      form.classList.add('was-validated');
    }, false)
  }

}, false);
